import { createGetKcContext } from "keycloakify/account";

export type KcContextExtension = { pageId: "applications.ftl"; }

export const { getKcContext } = createGetKcContext<KcContextExtension>({
	mockData: []
});

export const { kcContext } = getKcContext({});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
